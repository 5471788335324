<template>
  <div class="page">
    <el-form inline>
      <el-form-item label="销货单位：">
        <el-input />
      </el-form-item>
      <el-form-item label="单据日期：">
        <el-date-picker />
      </el-form-item>
      <el-form-item label="单据编号：">
        <el-input readonly />
      </el-form-item>
    </el-form>
    <el-table border>
      <el-table-column
          header-align="center"
          align="center"
          type="index"
          width="50"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="80">
        <template slot-scope="scope">
          <div
              style="
              display: flex;
              align-items: center;
              justify-content: space-around;
            "
          >
            <i class="el-icon-plus" style="cursor: pointer"></i>
            <i class="el-icon-delete" style="cursor: pointer"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="结算账户">
        <template slot-scope="scope">
          <el-select></el-select>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="付款金额">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="结算方式">
        <template slot-scope="scope">
          <el-select></el-select>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="结算号">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="margin: 10px 0">选择单源</el-button>
    <el-table border>
      <el-table-column
          header-align="center"
          align="center"
          type="index"
          width="50"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="80">
        <template slot-scope="scope">
          <div
              style="
              display: flex;
              align-items: center;
              justify-content: space-around;
            "
          >
            <i class="el-icon-plus" style="cursor: pointer"></i>
            <i class="el-icon-delete" style="cursor: pointer"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="单源编号">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="业务类别">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="单据日期">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="单据金额">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="已核销金额">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="未核销金额">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          label="本次核销金额"
      >
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-form style="margin-top: 25px" label-position="left" label-width="100px">
      <el-row :gutter="10">
        <el-col>
          <el-form-item label-width="0">
            <el-input
                type="textarea"
                :row="6"
                placeholder="暂无备注信息"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="整单折扣:">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="本次预收款:">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col>
          <el-form-item label="制单人:">
            <span>name</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

</template>

<script>
export default {
  name: "payment"
}
</script>

<style scoped>

.page {
  background-color: #fff;
  padding: 20px;
}
</style>